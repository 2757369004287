import { useEffect, useState } from 'react';
import { Outlet } from "react-router-dom";
import CodingProtectedRoute from '../services/ProtectedRoute';
import Navigation from './Navigation';
import { UserAuth } from '../services/authContext';
import { useLocation } from 'react-router-dom';
import Notification from '../components/notification/MainNotification';
import PIMSLogo from '../images/PIMS Logo.png';
import School from '../images/school.jpg';

const MainLayout = () => {
    const { pimsIP,conAPI } = UserAuth();
    const location = useLocation();
    const currentPath = location.pathname;
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const hasAcceptedPrivacyPolicy = localStorage.getItem('privacyPolicyAccepted');
        setIsVisible(!hasAcceptedPrivacyPolicy);
    }, []);

    const handleAccept = () => {
        localStorage.setItem('privacyPolicyAccepted', 'true');
        setIsVisible(false);
    };
    return (
        <div className='flex flex-col h-screen w-screen bg-slate-900'>
            <div>
                {
                    ((currentPath == '/') || !pimsIP) && (
                        <Navigation />
                    )
                }
            </div>
            {
                isVisible && (
                    <div className="fixed flex-col inset-0 flex items-center justify-center bg-black bg-opacity-50">
                        <img src={School} alt="school" className="absolute w-full h-full object-cover opacity-40 z-0" />
                        <div className="bg-white p-6 rounded-lg shadow-md text-center border-b-8 border-purple-500 z-10">
                            <img src={PIMSLogo} className='w-[100px] m-auto' />
                            <h2 className="text-lg font-semibold">Privacy Policy</h2>
                            <h2 className="text-lg font-semibold mb-4">นโยบายความเป็นส่วนตัว</h2>
                            <p className="text-gray-600">
                                Pimandek Chiang Mai website collects some user information for essential features
                            </p>
                            <p className="text-gray-600">
                                เว็บไซต์โรงเรียนพิมานเด็กเชียงใหม่ได้เก็บข้อมูลส่วนตัวบางส่วนของผู้ใช้งานก่อนที่จะเข้าใช้งานฟีเจอร์ต่างๆ
                            </p>
                            <p className="text-blue-600 hover:text-blue-400 mt-2 underline p-2 bg-slate-100 rounded-md">
                                <a
                                    href={conAPI()+'/privacypolicy'}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy Policy / นโยบายความเป็นส่วนตัว
                                </a>
                            </p>
                            <p className="text-gray-400 mt-2">
                                Accepting the privacy & policy by clicking “Accept”
                            </p>
                            <p className="text-gray-400">
                                คุณสามารถยินยอมนโยบายความเป็นส่วนตัวได้ โดยคลิก “ยอมรับ”
                            </p>
                            <button
                                className="font-bold mt-4 px-4 py-2 bg-gradient-to-l from-indigo-500 via-purple-500 to-pink-500 hover:from-indigo-400 hover:via-purple-400 hover:to-pink-400 text-white rounded"
                                onClick={handleAccept}
                            >
                                Accept / ยอมรับ
                            </button>
                        </div>
                    </div>
                )
            }
            {
                !isVisible && (
                    <>
                        <Notification />
                        <div className='flex flex-grow overflow-auto bg-slate-900 app-panel'>
                            <CodingProtectedRoute>
                                <Outlet />
                            </CodingProtectedRoute>
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default MainLayout
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import React from "react";
import MainLayout from './layouts/MainLayout';
import Home from './pages/home/Home';
import { AuthContextProvider } from './services/authContext';
import PIMS from './pages/pims/PIMS';
import LoiKrathongPage from './pages/krathong/LoiKrathongPage';

function App() {
  return (
    <div>
      <Toaster />
      <React.Suspense fallback={<div>Loading...</div>}>
        <AuthContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainLayout />}>
                <Route index element={<Home />} />
                <Route path='/pims' element={<PIMS/>}/>
                <Route path="*" element={<Home/>} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AuthContextProvider>
      </React.Suspense>
    </div>
  );
}

export default App;

import { UserAuth } from '../services/authContext';
import LoadingSpinner from '../components/loading/LoadingSpinner';

const CodingProtectedRoute = ({ children, src, callback }) => {
    const { user } = UserAuth();

    if (user !== null && user !== undefined) {
        return children;
    }
    if (user === null) {
        return children;
    }
    return (
        <div className='flex flex-grow justify-center bg-slate-900'>
            <LoadingSpinner />
        </div>
    );
};

export default CodingProtectedRoute;
import { auth, provider, db, googleProvider } from './firebase';
import { createContext, useContext, useEffect, useState } from 'react';
import {
    onAuthStateChanged,
    signOut,
    signInWithPopup,
} from "firebase/auth";
import axios from 'axios';

export const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(undefined);
    const [photo, setPhoto] = useState(undefined);

    const signInGoogle = async () => {
        return signInWithPopup(auth, googleProvider);
    }

    const logOut = async () => {
        setPhoto(undefined);
        return signOut(auth);
    }

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                setUser(currentUser);
                setPhoto(currentUser.photoURL);
            }
            else {
                setUser(null);
            }
        });
        // getPIMS();
        return () => {
            unsubscribe();
        };
    }, []);
    const [pimsIP, setPIMSIP] = useState(process.env.REACT_APP_PIMS_IP);
    // const getPIMS = () => {
    //     getDocs(collection(db, "pims")).then(query => {
    //         query?.forEach(doc => {
    //             if (doc.exists) {
    //                 const doc_data = doc.data();
    //                 if (doc_data.ip) {
    //                     setPIMSIP(doc_data.ip);
    //                 }
    //             }
    //         });
    //     });
    // }
    const [serverIsOpen, setServerIsOpen] = useState(false);

    useEffect(() => {
        console.log(pimsIP);
        if (pimsIP) {
            const intervalId = setInterval(() => {
                axios
                    .get(pimsIP)
                    .then(response => {
                        if (response.status === 200) {
                            setServerIsOpen(true);
                            clearInterval(intervalId);
                        }
                    })
                    .catch(error => {
                        console.error("Server is not open yet. Keep trying...");
                        setServerIsOpen(false);
                        // getPIMS();
                    });
            }, 2000);
            axios
                .get(pimsIP)
                .then(response => {
                    if (response.status === 200) {
                        setServerIsOpen(true);
                    }
                })
                .catch(error => {
                    console.error("Server is not open yet. Keep trying...");
                    setServerIsOpen(false);
                });
            return () => clearInterval(intervalId);
        }
    }, [pimsIP]);
    useEffect(()=>{
        const intervalId = setInterval(() => {
            // getPIMS();
        }, 2000);
        return () => clearInterval(intervalId);
    },[]);
    const [pims, setPIMS] = useState(false);
    const conAPI = () => {
        return (process.env.REACT_APP_ENVIRONMENT === "dev") ? "http://localhost:8080" : process.env.REACT_APP_API_IP;
    }
    return (
        <UserContext.Provider value={{ user, logOut, signInGoogle, photo, setPhoto, serverIsOpen, pims, setPIMS, pimsIP,conAPI  }}>
            {children}
        </UserContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(UserContext);
};
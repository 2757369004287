import React, { useEffect, useState } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';
import BDCard from '../../images/BDCard.png';
import BirthDayCard from './BirthDayCard';
import axios from 'axios';
import './notification.css';
import { db } from '../../services/firebase';
import { collection, doc, setDoc, getDocs, getDoc, query } from "firebase/firestore";
import { UserAuth } from '../../services/authContext';
import moment from 'moment-timezone';

const MainNotification = () => {
    const { user, conAPI } = UserAuth();
    const [open, setOpen] = useState(true);
    const [bd, setBD] = useState(undefined);
    useEffect(() => {
        getLikes();
    }, []);
    const getLikes = async () => {
        await axios.get(conAPI() + '/birthday').then((res) => {
            const list = [];
            if (res.data) {
                const data = res.data.now;
                for (let student of data) { // list นักเรียน
                    list.push(student);
                }
            }
            console.log(list)
            setBD(list);
        });
    }
    const isLikes = (person) => {
        if (person.birthday === null) return null;
        if (person?.birthday?.likes) {
            console.log(person?.birthday?.likes);
            for (let like of person?.birthday?.likes) {
                if(like?.uid === user?.uid) return true;
            }
        }
        // if (person.birthday && person.birthday?.likes?.filter(like => like.uid === user.uid).length > 0) return true;
        return false;
    }
    return (
        <>
            {
                open && (
                    <div className='fixed w-screen h-screen flex justify-center justify-items-center z-[1000] backdrop-blur-sm'>
                        <div className="snowflakes absolute z-[1001]" aria-hidden="true">
                            <div className="snowflake">🎉</div>
                            <div className="snowflake">🎁</div>
                            <div className="snowflake">🎉</div>
                            <div className="snowflake">🎁</div>
                            <div className="snowflake">🎂</div>
                            <div className="snowflake">🎁</div>
                            <div className="snowflake">🎉</div>
                            <div className="snowflake">🍰</div>
                            <div className="snowflake">🎂</div>
                            <div className="snowflake">🍰</div>
                        </div>
                        <div className='flex flex-col bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-600 w-[80%] h-[auto] max-h-[80%] lg:w-[50%] md:w-[70%] sm:w-[80%] m-auto rounded-md'>
                            <div className='flex-grow flex flex-col overflow-auto gap-4 p-4 m-4 justify-stretch'>
                                {
                                    bd && bd.length > 0 && bd.map((person, i) => <BirthDayCard numLike={person?.birthday?.likes?.length} getLikes={getLikes} isLike={isLikes(person)} id={person?.id} name={person?.nameTitle + " " + person?.firstName + " " + person?.lastName} img={BDCard} classroom={person?.class?.name} nickname={person?.nickname} date={moment(person?.birthdate).tz('Asia/Bangkok')} key={i} />)
                                }
                                {
                                    bd && bd.length <= 0 && (
                                        <BirthDayCard name={"ไม่มีนักเรียนวันเกิดในวันนี้:)"} img={BDCard} />
                                    )
                                }
                                {
                                    bd === undefined && (
                                        <>
                                            <div className='relative'>
                                                <img src={BDCard} className='w-full' />
                                                <div className='absolute top-[55%] left-[50%] text-[3.5vw] translate-x-[-50%]'>
                                                    Loading...
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            <div className='absolute w-[80%] lg:w-[50%] md:w-[70%] sm:w-[80%]'>
                                <AiFillCloseCircle className='text-[40px] m-2 ml-auto text-white hover:text-red-200 active:text-red-500' onClick={() => { setOpen(false) }} />
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default MainNotification
import React from 'react';
import { collection, doc, setDoc, getDocs, getDoc } from "firebase/firestore";
import { db } from '../../services/firebase';
import { UserAuth } from '../../services/authContext';
import { toast } from 'react-hot-toast';
import moment from 'moment-timezone';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import axios from 'axios';

const BirthDayCard = ({ img, getLikes, numLike = -1, isLike = false, id = -1, nickname = "", classroom = "", date = new Date(), name = "" }) => {
    const { user, conAPI } = UserAuth();

    const processDate = (birthdate) => {
        if (birthdate) {
            birthdate = moment(birthdate);
            let day = String(birthdate.date()).padStart(2, '0');
            let month = String(birthdate.month() + 1).padStart(2, '0');
            let year = birthdate.year() + 543;

            let birthdateThai = `${day}/${month}/${year.toString()}`;
            return birthdateThai;
        }
        return "";
    }

    function nong(name) {
        //login
        let str = name.toString();
        if (str.includes("น้อง")) {//มีคำว่าน้องแล้วนะ//
            return name;
        }
        else {
            return "น้อง" + name;
        }
    }

    const handleLike = async () => {
        if (id != -1 && user) {
            try {
                const res = await axios.post(conAPI() + "/private/birthday/like", {
                    birthdayId: id,
                },{
                    headers: {
                        'Authorization': "Bearer " + user.accessToken
                    }
                });

                if (res.status === 200) {
                    getLikes();
                    toast.success("ถูกใจ " + nong(nickname) + " สำเร็จ");
                } else {
                    throw new Error("Unable to like");
                }
            }
            catch (e) {
                console.log(e);
                toast.error("ถูกใจ " + nong(nickname) + " ไม่สำเร็จ");
            }
        }
    }

    const handleUnLike = async () => {
        if (id != -1 && user) {
            try {
                const res = await axios.post(conAPI() + "/private/birthday/unlike", {
                    birthdayId: id,
                },{
                    headers: {
                        'Authorization': "Bearer " + user.accessToken
                    }
                });

                if (res.status === 200) {
                    getLikes();
                    toast.success("เลิกถูกใจ " + nong(nickname) + " สำเร็จ");
                } else {
                    throw new Error("Unable to unlike");
                }
            }
            catch (e) {
                console.log(e);
                toast.error("เลิกถูกใจ " + nong(nickname) + " ไม่สำเร็จ");
            }
        }
    }
    return (
        <div>
            <div className='relative'>
                <img src={img} className='w-full' />
                <div className='absolute top-[50%] left-[50%] text-[3.5vw] translate-x-[-50%]'>
                    {
                        nickname != "" && nong(nickname)
                    }
                </div>
                <div className='absolute top-[66%] left-[50%] text-[1.5vw] translate-x-[-50%]'>
                    {
                        name
                    }
                </div>
                <div className='absolute top-[72%] left-[50%] text-[2vw] translate-x-[-50%]'>
                    {
                        classroom
                    }
                </div>
                <div className='absolute top-[82%] left-[50%] text-[2vw] translate-x-[-50%]'>
                    {
                        date != "" && processDate(date)
                    }
                </div>
                {
                    id != -1 && !user && numLike !== -1 && (
                        <div className='absolute bottom-0 right-0 w-[100px] flex justify-end p-2 bg-transparent'>
                            <div className='relative'>
                                <button className=' bg-pink-100 rounded-full flex p-1'>
                                    <AiFillHeart className='text-[50px] text-red-500 relative top-1' />
                                </button>
                                <div className='absolute text-[21px] text-white left-[50%] top-[50%] translate-x-[-50%] translate-y-[-40%] font-bold'>
                                    {numLike}
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    id != -1 && user && isLike !== null && isLike === false && (
                        <div className='absolute bottom-0 right-0 w-[100px] flex justify-end p-2 bg-transparent'>
                            <div className='relative' onClick={handleLike}>
                                <button className=' bg-pink-100 rounded-full flex p-1'>
                                    <AiOutlineHeart className='text-[50px] text-red-500 relative top-1' />
                                </button>
                                <div className='absolute top-0 left-0 w-[58px] h-[58px] flex text-[21px] text-black justify-center font-bold'>
                                    <div className='m-auto'>
                                        {numLike}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    id != -1 && user && isLike !== null && isLike === true && (
                        <div className='absolute bottom-0 right-0 w-[100px] flex justify-end p-2 bg-transparent'>
                            <div className='relative' onClick={handleUnLike}>
                                <button className=' bg-pink-100 rounded-full flex p-1'>
                                    <AiFillHeart className='text-[50px] text-red-500 relative top-1' />
                                </button>
                                <div className='absolute top-0 left-0 w-[58px] h-[58px] flex text-[21px] text-black justify-center font-bold'>
                                    <div className='m-auto'>
                                        {numLike}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default BirthDayCard
import { useEffect } from 'react';
import { UserAuth } from '../../services/authContext';

const PIMS = () => {
    const { pimsIP } = UserAuth();

    useEffect(() => {
        if (pimsIP === undefined) {
            window.location.href = '/';
        } else {
            window.location.href = pimsIP;
        }
    }, [pimsIP]);

    return null; // This component doesn't render anything because it redirects
};

export default PIMS;
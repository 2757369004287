import React from "react";
import "./spinner.css";

export default function LoadingSpinner({text}) {
    return (
        <div className="spinner-container m-auto">
            <div>
                <div className="loading-spinner m-auto"></div>
            </div>
            <div className="text-center text-white font-bold text-[30px] mt-3">
                {
                    text || "Loading"
                }
            </div>
        </div>
    );
}
import React, { useState, useEffect, useRef } from 'react';
import School from '../../images/school.jpg';
import "./Home.css";
import Lighthouse from '../../images/lighthouse.png';
import Logo from '../../images/PimandekChaingmai.png';
import LeaderInMe from '../../images/leaderinme.png';
import { Stage, Layer, Rect, Text, Circle, Line, Polygon } from 'react-konva';
import Boat1 from '../../images/boat1.png';
import Boat2 from '../../images/boat2.png';
import Boat3 from '../../images/boat3.png';
import Boat4 from '../../images/boat4.png';
import Boat5 from '../../images/boat5.png';
import LoadingSpinner from '../../components/loading/LoadingSpinner';
import { UserAuth } from '../../services/authContext';
import { collection, doc, setDoc, getDocs, getDoc } from "firebase/firestore";
import { db } from '../../services/firebase';
import toast from 'react-hot-toast';
import LoiKrathong from '../../components/loikrathong/LoiKrathong';

const list = [Boat1, Boat2, Boat3, Boat4, Boat5];
let tW = 60;
let tY = 100;
let wT = 200;
let tS = 10;
let vertex = [-tW / 2, 0, tW / 2, tW / 2, 0, 0, 100, 400, -100, 400];
let vertex1 = [-tW / 2, 0, tW / 2, 0, tW / 2, 0, 100, 400, -100, 400];
let wish = [];
let uWish = [];
let uBoat = [];
let pBoat = [];

const Home = () => {
    const chooseWish = () => {
        if (wish.length > 0) {
            if (wish.length == 1) {
                const ret = wish[0];
                wish = [];
                return ret;
            }
            else {
                const w = wish.map(item => item);
                const rand = Math.floor(Math.random() * w.length);
                const newWish = w?.filter((item, index) => index != rand);
                wish = newWish;
                return w[rand];
            }
        }
        else {
            return {
                id: -1,
                wish: "",
                type: list[0],
                author: {
                    name: "",
                    uid: ""
                }
            }
        }
    }
    const [boats, setBoats] = useState([]);
    const [light, setLight] = useState(false);
    const update = () => {
        const nB = pBoat.map(item => item);
        let dist = 0;
        let min = 200;
        let real_pos = 100;
        for (let item of nB) {
            if (item.x >= 110) {
                item.x = -10;
                item.speed = Math.random() * 0.05 + 0.025;
                const iWish = item?.wish;
                if (iWish && iWish.id != -1) {
                    wish.push(iWish);
                }
                item.wish = chooseWish();
                item.type = list[item.wish.type - 1];
            }
            else {
                item.x += item.speed;
                if (Math.abs(min) > Math.abs(item.x - 50)) {
                    min = item.x - 50;
                    if (min > 0) {
                        min += tS;
                    }
                    else {
                        min += tS - 3;
                    }
                    real_pos = item.x;
                }
            }
        }
        for (let item of uBoat) {
            if (item.x >= 110) {
                item.x = -10;
                item.speed = Math.random() * 0.05 + 0.025;
            }
            else {
                item.x += item.speed;
                if (Math.abs(min) > Math.abs(item.x - 50)) {
                    min = item.x - 50;
                    if (min > 0) {
                        min += tS;
                    }
                    else {
                        min += tS - 3;
                    }
                    real_pos = item.x;
                }
            }
        }
        if (min <= 70) {
            setLight(true);
        }
        else {
            setLight(false);
        }
        let move = 0;
        let distt = Math.abs((vertex[8] + 125) / window.innerWidth * 100 - min);
        if (distt >= 0.5) {
            move = -1 * Math.sign((vertex[8] + 125) / window.innerWidth * 100 - min);
        }
        const spd = distt / 2;
        if (min > 5) {
            vertex = [-tW / 2, 0, tW / 2, 0, tW / 2, 0, vertex[8] + move * spd + wT, 350, vertex[8] + move * spd, 350, -tW / 2, 25];
            vertex1 = [-tW / 3, 0, tW / 3, 0, tW / 3, 0, vertex[8] + move * spd + wT - 50, 350, vertex[8] + move * spd + 50, 350, -tW / 3, 25];
        }
        else if (min < -5) {
            vertex = [-tW / 2, 0, tW / 2, 0, tW / 2, 25, vertex[8] + move * spd + wT, 350, vertex[8] + move * spd, 350];
            vertex1 = [-tW / 3, 0, tW / 3, 0, tW / 3, 25, vertex[8] + move * spd + wT - 50, 350, vertex[8] + move * spd + 50, 350];
        }
        else {
            vertex = [-tW / 2, 0, tW / 2, 0, tW / 2, 0, vertex[8] + move * spd + wT, 350, vertex[8] + move * spd, 350];
            vertex1 = [-tW / 3, 0, tW / 3, 0, tW / 3, 0, vertex[8] + move * spd + wT - 50, 350, vertex[8] + move * spd + 50, 350];
        }
        pBoat = nB.map(item => item);
        setBoats(nB);
    };
    useEffect(() => {
        let interval = setInterval(() => {
            update()
        }, 10);
        return () => clearInterval(interval);
    }, []);
    const listRef = useRef();
    const [width, setWidth] = useState();
    const getListSize = () => {
        const newWidth = listRef?.current?.clientWidth;
        if (newWidth == 200) {
            tW = 45;
            tY = 163;
            wT = 150;
            tS = 30;
        }
        else {
            tW = 60;
            tY = 100;
            wT = 200;
            tS = 10;
        }
        if (newWidth) {
            setWidth(newWidth);
        }
    };
    useEffect(() => {
        window.addEventListener("resize", getListSize);
        getListSize()
    }, []);
    const getAllWish = () => {
        getDocs(collection(db, "boats")).then(query => {
            const boats = [];
            const uBoats = [];
            query?.forEach(doc => {
                if (doc.exists) {
                    const doc_data = doc.data();
                    if (doc_data?.author?.uid === user?.uid) {
                        uBoats.push(doc_data);
                    }
                    else {
                        boats.push(doc_data);
                    }
                }
            });
            uWish = uBoats;
            setAllWish(boats);
        });
    }
    const [allWish, setAllWish] = useState([]);
    useEffect(() => {
        if (allWish?.length > 0) {
            wish = allWish.map(item => item);
            //create boat at maximum 5
            const bboat = [];
            for (let i = 0; i < 5 && i < allWish.length; i++) {
                const ww = chooseWish();
                const boat = {
                    x: Math.floor(Math.random() * 100),
                    y: 0,
                    speed: Math.random() * 0.05 + 0.025,
                    wish: ww,
                    type: list[ww.type - 1]
                }
                bboat.push(boat);
            }
            setBoats(bboat);
            pBoat = bboat.map(item => item);
            vertex = [-tW / 2, 0, tW / 2, tW / 2, 0, 0, 100, 400, -100, 400];
            vertex1 = [-tW / 2, 0, tW / 2, 0, tW / 2, 0, 100, 400, -100, 400];
        }
        if (uWish?.length > 0) {
            uBoat = [];
            for (let item of uWish) {
                const boat = {
                    type: list[item.type - 1],
                    x: -10,
                    y: 0,
                    speed: Math.random() * 0.05 + 0.025,
                    wish: item
                }
                uBoat.push(boat);
            }
            vertex = [-tW / 2, 0, tW / 2, tW / 2, 0, 0, 100, 400, -100, 400];
            vertex1 = [-tW / 2, 0, tW / 2, 0, tW / 2, 0, 100, 400, -100, 400];
        }
    }, [allWish]);
    const { user } = UserAuth();
    useEffect(() => {
        getAllWish();
    }, [user]);
    return (
        <div className='overflow-x-hidden app-panel'>
            <div className='w-screen h-screen relative top-0 left-0 overflow-hidden'>
                <img src={School} alt="school" className="absolute w-full h-full object-cover opacity-80 z-0" />
                <div>
                    <img src={Lighthouse} ref={listRef} alt="lighthouse" className="z-[0] left-[50%] absolute w-[200px] sm:w-[200px] md:w-[250px] lg:w-[250px] translate-x-[-50%] translate-y-[150px] bottom-[230px]" />
                </div>
                <div className="z-[2] absolute bottom-[5px] w-full">
                    <div className='relative'>
                        {
                            light && (
                                <Stage width={window.innerWidth} height={500}>
                                    <Layer>
                                        <Line
                                            x={window.innerWidth / 2}
                                            y={tY}
                                            points={vertex}
                                            closed
                                            opacity={0.6}
                                            fillLinearGradientStartPoint={{ x: -50, y: -50 }}
                                            fillLinearGradientEndPoint={{ x: 50, y: 50 }}
                                            fillLinearGradientColorStops={[0, 'yellow', 1, 'yellow']}
                                        />
                                    </Layer>
                                </Stage>
                            )
                        }
                    </div>
                </div>
                <div className="z-[4] absolute bottom-[5px] w-full">
                    <div className='relative'>
                        {
                            light && (
                                <Stage width={window.innerWidth} height={500}>
                                    <Layer>
                                        <Line
                                            x={window.innerWidth / 2}
                                            y={tY}
                                            points={vertex1}
                                            closed
                                            opacity={0.4}
                                            fillLinearGradientStartPoint={{ x: -50, y: -50 }}
                                            fillLinearGradientEndPoint={{ x: 50, y: 50 }}
                                            fillLinearGradientColorStops={[0, 'white', 1, 'white']}
                                        />
                                    </Layer>
                                </Stage>
                            )
                        }
                    </div>
                </div>
                <div className="z-[3] absolute bottom-[230px] translate-y-[150px] w-full h-[300px]">
                    {
                        boats?.map((boat, index) => <Boat key={index} type={boat.type} x={boat.x} y={boat.y} wish={boat.wish} />)
                    }

                    {
                        uBoat?.map((boat, index) => <Boat key={index} type={boat.type} x={boat.x} y={boat.y} wish={boat.wish} />)
                    }
                </div>
                <div className="z-[4] absolute bottom-[230px] translate-y-[150px] w-full h-[300px]">
                    {
                        boats?.map((boat, index) => <BoatDialog key={index} type={boat.type} x={boat.x} y={boat.y} wish={boat.wish} />)
                    }
                    {
                        uBoat?.map((boat, index) => <BoatDialog key={index} type={boat.type} x={boat.x} y={boat.y} wish={boat.wish} />)
                    }
                </div>
                <div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>
                <div></div>
                <div className='w-full z-40 absolute left-[50%] translate-x-[-50%] text-white text-center font-bold text-[20px] sm:text-[20px] md:text-[30px] lg:text-[30px]' style={{ fontFamily: '"Prompt", "Arial"' }}>
                    <div className='mx-[70px] mt-[100px] flex flex-col sm:flex-col md:flex-row lg:flex-row justify-center gap-4'>
                        <div className='flex justify-center'>
                            <img src={Logo} alt="logo" className="object-contain w-[100px] sm:w-[100px] md:w-[125px] lg:w-[150px] h-[100px] sm:h-[100px] md:h-[125px] lg:h-[150px]" />
                        </div>
                        <div className='mt-5'>
                            <div className='pimandek font-light'>
                                พิมานเด็กเชียงใหม่ โรงเรียนผู้นำยุคใหม่
                            </div>
                            <div className='mt-[20px] mx-[-20px]'>
                                Pimandek ChiangMai School
                            </div>
                        </div>
                        <div className='flex justify-center'>
                            <img src={LeaderInMe} alt="logo" className="object-contain w-[100px] sm:w-[100px] md:w-[125px] lg:w-[150px] h-[100px] sm:h-[100px] md:h-[125px] lg:h-[150px]" />
                        </div>
                    </div>
                </div>
            </div>
            <FormLighthouse />
        </div>
    )
}

const Boat = ({ type, x, y }) => {
    return (
        <div className='absolute boat h-auto' style={{ left: x.toString() + "%", bottom: y }} >
            <div className='relative'>
                <img src={type} className="w-[150px] sm:w-[150px] md:w-[200px] lg:w-[200px]" />
            </div>
        </div>
    )
}
const BoatDialog = ({ type, x, y, wish }) => {
    const [at, setAt] = useState(false);
    const handleText = () => { //hello
        if (wish?.wish.length > 150) {
            return wish?.wish?.substring(0, 150) + "...";
        }
        else {
            return wish?.wish;
        }
    }
    return (
        <div className='absolute boat h-auto' style={{ left: x.toString() + "%", bottom: y }} >
            <div className='relative' onMouseEnter={() => { setAt(true) }} onMouseLeave={() => { setAt(false) }}>
                <div className='relative'>
                    <img src={type} className="w-[150px] sm:w-[150px] md:w-[200px] lg:w-[200px]" style={{ visibility: "hidden" }} />
                </div>
                <div className='relative bottom-[100px] text-white text-center w-[150px] sm:w-[150px] md:w-[200px] lg:w-[200px] flex justify-center'>
                    <img src={wish?.author?.url + "?type=large"} className="w-[60px] h-[60px] rounded-full" />
                </div>
                {
                    at && (
                        <div className='relative bottom-[90px] text-center w-[150px] sm:w-[150px] md:w-[200px] lg:w-[200px]'>
                            <div className='relative bottom-0 break-words bg-[#0201077e] p-2 rounded-lg text-[15px] text-white'>
                                {
                                    handleText()
                                }
                            </div>
                        </div>
                    )
                }

            </div>
        </div>
    )
}


const FormLighthouse = () => {
    const [type, setType] = useState(Boat1);
    const [wish, setWish] = useState("");
    const [submit, setSubmit] = useState(2);
    const { user } = UserAuth();
    useEffect(() => {
        if (user) {
            setSubmit(0);
        }
        else {
            setSubmit(-1)
        }
    }, [user]);
    const getIndex = (boat) => {
        let ind = -1;
        for (let i = 0; i < list.length; i++) {
            if (list[i] === type) {
                ind = i + 1;
            }
        }
        return ind;
    }
    const releaseBoat = (wish) => {
        if (user) {
            const obj = {
                wish: wish,
                time: new Date(),
                type: getIndex(type),
                author: {
                    name: user?.displayName,
                    url: user?.photoURL,
                    uid: user?.uid
                }
            }
            setDoc(doc(db, "boats", user?.uid), obj).then(msg => {
                setSubmit(2);
                toast.success("ปล่อยเรือเสร็จสมบูรณ์");
                uBoat = [];
                const boat = {
                    type: type,
                    x: -10,
                    y: 0,
                    speed: Math.random() * 0.05 + 0.025,
                    wish: obj
                }
                uBoat.push(boat);
            })
                .catch(e => {
                    setSubmit(0);
                    toast.error("ปล่อยเรือไม่สำเร็จ");
                })
        }
        else {
            setSubmit(-1);
        }
    }
    return (
        <div className='w-screen h-screen relative top-0 left-0 overflow-hidden bg-gradient-to-b from-[#0450b2]'>
            <div className="wrapper">
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
                <div><span className="dot"></span></div>
            </div>
            <div className='w-full h-full flex justify-center'>
                <div className='z-10 w-[500px] h-[600px] my-auto mx-4 flex-col flex relative'>
                    <div className='bottom-[0] translate-y-[-100px] left-[50%] translate-x-[-50%] flex justify-center absolute'>
                        <img src={type} className="boat w-[150px] h-auto" />
                    </div>
                    <div className='flex-grow bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-md p-2 text-white'>
                        {
                            (submit == 0) && (
                                <>
                                    <div className='text-center pimandek text-[25px] font-bold'>
                                        ⭐{" In The Future I Wanna Be "}⭐
                                    </div>
                                    <div className='text-center pimandek text-[20px]'>
                                        ในอนาคตฉันอยากจะเป็น
                                    </div>
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        if (wish?.length > 150) {
                                            toast.error("ห้ามใช้คำเกิน 150 ตัว");
                                        }
                                        else {
                                            releaseBoat(wish);
                                            setSubmit(1);
                                        }
                                    }}
                                        className="flex flex-col w-full h-full p-2"
                                    >
                                        <input type="text" className='text-center font-bold pimandek p-4 text-[25px] rounded-md m-2 text-black' placeholder='Leader Doctor Programmer...' value={wish} onChange={(e) => { setWish(e.target.value) }} required />
                                        <div>
                                            <div className='text-center pimandek font-bold text-[25px]'>
                                                🚢{" Select a Ship "}🚢
                                            </div>
                                            <div className='text-center pimandek text-[20px]'>
                                                เลือกเรือของเรา
                                            </div>
                                            <div className='flex gap-4 justify-center m-2'>
                                                <button type='button' className='bg-yellow-400 p-2 w-[70px] h-[70px] font-bold text-[25px] rounded-full border-b-4 active:border-b-0 active:bg-yellow-500' onClick={() => { setType(Boat1) }}>
                                                    1
                                                </button>
                                                <button type='button' className='bg-green-400 p-2 w-[70px] h-[70px] font-bold text-[25px] rounded-full border-b-4 active:border-b-0 active:bg-green-500' onClick={() => { setType(Boat2) }}>
                                                    2
                                                </button>
                                                <button type='button' className='bg-blue-400 p-2 w-[70px] h-[70px] font-bold text-[25px] rounded-full border-b-4 active:border-b-0 active:bg-blue-500' onClick={() => { setType(Boat3) }}>
                                                    3
                                                </button>
                                                <button type='button' className='bg-red-400 p-2 w-[70px] h-[70px] font-bold text-[25px] rounded-full border-b-4 active:border-b-0 active:bg-red-500' onClick={() => { setType(Boat4) }}>
                                                    4
                                                </button>
                                                <button type='button' className='bg-purple-400 p-2 w-[70px] h-[70px] font-bold text-[25px] rounded-full border-b-4 active:border-b-0 active:bg-purple-500' onClick={() => { setType(Boat5) }}>
                                                    5
                                                </button>
                                            </div>
                                        </div>
                                        <div className='flex-grow'></div>
                                        <input type="submit" value="Submit" className='rounded-lg p-2 realtive top-[-70px] font-bold pimandek text-white text-[25px] bg-gradient-to-r from-cyan-500 to-blue-500 relative bottom-0 border-b-4 active:border-b-0' />
                                    </form>
                                </>
                            )
                        }
                        {
                            (submit == 1) && (
                                <div className='mt-[200px]'>
                                    <LoadingSpinner text={"กำลังปล่อยเรือ"} />
                                </div>
                            )
                        }
                        {
                            (submit == 2) && (
                                <div className='mt-[200px] w-full text-center pimandek font-bold text-[25px] stroke-slate-900 stroke-2'>
                                    {"✔️ ปล่อยเรือเสร็จสมบูรณ์ ✔️"}
                                    <div className='font-light'>
                                        ท่านสามารถดูเรือของท่านได้ด้านบนหน้าหลัก
                                    </div>
                                    <div className='w-full flex justify-center'>
                                        <button onClick={() => { setSubmit(0) }} className='px-2 py-1 text-center text-white bg-slate-600 rounded-md mt-[300px] hover:bg-slate-700 active:bg-slate-500'>
                                            แก้ไขเรือ
                                        </button>
                                    </div>
                                </div>
                            )
                        }
                        {
                            (submit == -1) && (
                                <div className='mt-[200px] w-full text-center pimandek font-bold text-[25px] stroke-slate-900 stroke-2'>
                                    กรุณาเข้าสู่ระบบก่อนใช้งาน
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
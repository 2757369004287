import React, { useState, useEffect } from 'react';
import Krathong from './Krathong';
import KrathongCard from './KrathongCard';
import moon from '../../images/moon.png';
import temple1 from '../../images/temple1.png';
import temple2 from '../../images/temple2.png';
import { UserAuth } from '../../services/authContext';
import { fetchKrathongs } from '../../fetch/krathongFetch';
import toast from 'react-hot-toast';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { createKrathong, createKrathongSpecial } from '../../fetch/krathongFetch';

const MySwal = withReactContent(Swal);

const LoiKrathong = () => {
    const { user } = UserAuth();

    const [unused, setUnused] = useState([]);
    const [inused, setInused] = useState([]);
    const [used, setUsed] = useState([]);

    const [timeDelay, setTimeDelay] = useState(0);
    const [showCard, setShowCard] = useState(false);

    useEffect(() => {
        fetchKrathongs().then((res) => {
            const obj = res;
            setUnused(obj);
        });
    }, []);

    const choosePray = (kCount) => {
        if (unused) {
            const unu = [];
            for(let item of unused){
                unu.push(item);
            }
            const inu = [];
            let dd = 0;
            for (let i = 0; i < kCount; i++) {
                if (unu.length <= 0) {
                    break;
                }
                //choose
                let rC = Math.floor(Math.random() * unu.length);
                unu[rC].delay = dd;
                dd += Math.random() * 3 + 2;
                inu.push(unu[rC]);
                unu.splice(rC, 1);
            }
            setTimeDelay(dd);
            setUnused(unu);
            setInused(inu);
        }

    }
    useEffect(() => {
        //initialze
        if (unused.length > 0) {
            let kCount = unused.length;
            choosePray(kCount);
        }
    }, [unused]);
    const loiKrathong = async (type, wish) => {
        const response = await createKrathong(user?.accessToken, {
            type: type,
            wish: wish
        });
        setShowCard(false);
        toast.success("ปล่อยกระทงเสร็จสมบูรณ์");
        const obj = response;
        const uu = used.map(item => item);
        uu.push(obj);
        setUsed(uu);
    }
    const loiKrathongSpecial = async (type, wish) => {
        const response = await createKrathongSpecial({
            type: type,
            wish: wish,
            secret: secret
        });
        setShowCard(false);
        toast.success("ปล่อยกระทงเสร็จสมบูรณ์");
        const obj = response;
        const uu = used.map(item => item);
        uu.push(obj);
        setUsed(uu);
    }

    const [test1, setTest1] = useState(false);
    const [test2, setTest2] = useState(false);
    const [allowTest, setAllowTest] = useState(false);
    const [secret, setSecret] = useState(null);

    useEffect(() => {
        if (test1 && test2) {
            MySwal.fire({
                title: 'Enter your secret',
                input: 'password',
                inputLabel: 'Secret',
                inputPlaceholder: 'Enter your secret',
                showCancelButton: true,
                inputValidator: (value) => {
                    if (!value) {
                        return 'You need to write something!';
                    }
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    setSecret(result.value);
                    setAllowTest(true);
                    setTest1(false);
                    setTest2(false);
                } else if (result.isDismissed) {
                    setAllowTest(false);
                    setTest1(false);
                    setTest2(false);
                }
            });
        }
    }, [test1, test2]);

    return (
        <>
            <div className="h-screen star flex bg-gradient-to-t from-[#3f63ff] to-[#0c1330] justify-center flex-col">
                <div className="flex justify-center">
                    <div className="relative top-[-20px] flex flex-col justify-center">
                        <div className='flex'>
                            {
                                (user || allowTest) && (
                                    <div>
                                        <img src={moon} className="w-[300px] h-[300px] active:animate-ping" onClick={() => { setShowCard(!showCard); }} />
                                        <img src={moon} className="w-[300px] h-[300px] active:animate-ping absolute top-0" onClick={() => { setShowCard(!showCard); }} />
                                    </div>
                                )
                            }
                            {
                                !user && !allowTest && (
                                    <div>
                                        <img src={moon} className="w-[300px] h-[300px] active:animate-ping" />
                                        <img src={moon} className="w-[300px] h-[300px] active:animate-ping absolute top-0" />
                                    </div>
                                )
                            }
                        </div>
                        {
                            (user || allowTest) && (
                                <>
                                    <div className="text-white font-bold text-[20px] text-center mt-[-20px]">
                                        {
                                            !showCard && (
                                                "กดที่พระจันทร์เพื่อลอยกระทง"
                                            )
                                        }
                                    </div>
                                    <div className="text-yellow-200 font-bold text-[20px] text-center mt-[-60px]">
                                        {
                                            !showCard && (
                                                "Press The Moon To Loy Krathong"
                                            )
                                        }
                                    </div>
                                </>
                            )
                        }
                        {
                            !user && !allowTest && (
                                <>
                                    <div className="text-white font-bold text-[20px] text-center mt-[-20px]">
                                        {
                                            !showCard && (
                                                "สุขสันต์วันลอยกระทง 2566"
                                            )
                                        }
                                    </div>
                                    <div className="text-yellow-200 font-bold text-[20px] text-center mt-[-60px]">
                                        {
                                            !showCard && (
                                                "Happy Loy Krathong Day 2023"
                                            )
                                        }
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
                <div className="flex flex-grow">
                    <div className="relative w-full h-full">
                        {
                            inused?.map((item, index) => {
                                return (
                                    <Krathong user={user} key={item?.time} timeDelay={timeDelay} row={index % 4 + 1} delay={item?.delay || 0} duration={Math.random() * 10 + 20} type={item?.type || (index % 4)} data={item} />
                                )
                            })
                        }
                        {
                            used?.map((item, index) => {
                                return (
                                    <Krathong user={user} key={item?.time} timeDelay={timeDelay} row={index % 4 + 1} delay={item?.delay || 0} duration={Math.random() * 10 + 20} type={item?.type || (index % 4)} data={item} />
                                )
                            })
                        }
                        <div>
                            <img onClick={() => { setTest1(true) }} src={temple1} className="absolute bottom-[290px] left-[-60px] w-[500px] z-[1]" />
                            <img onClick={() => { setTest2(true) }} src={temple2} className="absolute bottom-[290px] right-[-70px] w-[500px] z-[1]" />
                        </div>
                        <div>
                            <div className='grass'></div>
                        </div>
                        <div>
                            <div className="wave1"></div>
                            <div className="wave1"></div>
                            <div className="wave1"></div>
                            <div className="wave1"></div>
                            <div className="wave1"></div>
                        </div>
                        {
                            showCard && (
                                <KrathongCard test={allowTest} secret={secret} user={user} used={used} setUsed={setUsed} setShowCard={setShowCard} loiKrathong={loiKrathong} loiKrathongSpecial={loiKrathongSpecial} />
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoiKrathong
import React, { useEffect, useState } from 'react';
import { UserAuth } from '../services/authContext';
import { AiFillHome } from 'react-icons/ai';
import { BsFillTrophyFill } from 'react-icons/bs';
import toast from 'react-hot-toast';
import { storage } from '../services/firebase';
import { BsFacebook } from 'react-icons/bs';
import { FcGoogle } from 'react-icons/fc';
import {
    FacebookAuthProvider,
    updateProfile
} from "firebase/auth";
import {
    useNavigate,
    useLocation,
    Link
} from "react-router-dom";
import {
    ref,
    uploadBytes,
    getDownloadURL,
    listAll,
    list,
} from "firebase/storage";
import PIMSLogo from '../images/PIMS Logo.png';

const Navigation = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const [openUser, setOpenUser] = useState(false);
    const { signInGoogle, logOut, user, photo, setPhoto, serverIsOpen, pimsIP } = UserAuth();
    let navigate = useNavigate();
    let location = useLocation();
    useEffect(() => {
        //console.log(photo?.toString());
    }, [photo]);
    const [imageUpload, setImageUpload] = useState(null);
    const [imageUrls, setImageUrls] = useState([]);
    const imagesListRef = ref(storage, "images/");
    const uploadFile = () => {
        if (imageUpload == null) return;
        const imageRef = ref(storage, `images/${imageUpload.name}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setImageUrls((prev) => [...prev, url]);
                setPhoto(url);
            });
        });
    };
    return (
        <>
            <div className='h-[72px] bg-gradient-to-r bg-transparent fixed w-screen z-50'>
                <div className='flex w-full h-full justify-center items-center mx-auto'>
                    <button onClick={() => { setOpenMenu(!openMenu) }} className="inline-flex items-center p-2 ml-3 mr-0 text-sm z-40 rounded-lg  focus:outline-none focus:ring-2 text-slate-50 hover:bg-[#4646464d] focus:ring-[#ffffff]">
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                    </button>
                    {
                        openMenu && (
                            <div className="items-center w-full absolute top-[56px] sm:top-[56px] md:top-[-16px] lg:top-[-16px] z-30">
                                <ul className="flex md:flex-row lg:flex-row gap-3 justify-center p-4 mt-4 bg-transparent backdrop-blur-sm border-0 rounded-none overflow-hidden">
                                    <li>
                                        {
                                            (location.pathname === "/") && (<button onClick={() => { navigate("/"); }} className="flex gap-2 py-2 pr-4 pl-3 text-white bg-blue-700 rounded dark:text-white" aria-current="page"><AiFillHome className='text-lg' />Home</button>)
                                        }
                                        {
                                            (location.pathname !== "/") && (<button onClick={() => { navigate("/"); }} className="flex gap-2 py-2 pr-4 pl-3 text-white rounded hover:bg-[#3b57d4d5] dark:hover:text-white dark:border-gray-700"><AiFillHome className='text-lg' />Home</button>)
                                        }
                                    </li>
                                    {
                                        serverIsOpen && (
                                            <li>
                                                {
                                                    (location.pathname === "/pims") && (<button onClick={() => { navigate("/pims"); }} className="flex gap-2 py-2 pr-4 pl-3 text-white bg-blue-700 rounded dark:text-white" aria-current="page"><AiFillHome className='text-lg' />PIMS</button>)
                                                }
                                                {
                                                    (location.pathname !== "/pims") && (<button onClick={() => { navigate("/pims"); }} className="flex gap-2 py-2 pr-4 pl-3 text-white rounded hover:bg-[#3b57d4d5] dark:hover:text-white dark:border-gray-700"><AiFillHome className='text-lg' />PIMS</button>)
                                                }
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        )
                    }
                    <div className='flex text-white font-bold m-auto justify-center text-[20px] flex-grow mr-2 lg:absolute'></div>
                    <div className='lg:flex lg:flex-grow'></div>
                    {
                        (user === null) &&
                        <button className="mt-0 mr-4 bg-white font-bold text-black p-1 rounded-md px-2 z-50" onClick={() => {
                            const myPromise = signInGoogle().then(result => {
                                const credential = FacebookAuthProvider.credentialFromResult(result);
                                const photoUrl = result.user.photoURL;
                                setPhoto(photoUrl);
                            })
                            toast.promise(myPromise, {
                                loading: 'Loading',
                                success: 'Login Successfully',
                                error: 'Error when logging in',
                            })
                                .then((r) => r)
                                .catch((error) => { console.log(error); return error });
                        }}><div className='flex gap-2'><FcGoogle className='text-white text-[25px]' />เข้าสู่ระบบด้วย Google</div></button>
                        // <button className="mt-0 mr-4 bg-blue-600 font-bold text-white p-1 rounded-md px-2 z-50" onClick={() => {
                        //     const myPromise = signInGoogle().then(result => {
                        //         const credential = FacebookAuthProvider.credentialFromResult(result);
                        //         const photoUrl = result.user.photoURL;
                        //         setPhoto(photoUrl);
                        //     })
                        //     toast.promise(myPromise, {
                        //         loading: 'Loading',
                        //         success: 'Login Successfully',
                        //         error: 'Error when logging in',
                        //     })
                        //         .then((r) => r)
                        //         .catch((error) => { console.log(error); return error });
                        // }}><div className='flex gap-2'><BsFacebook className='text-white text-[25px]' />เข้าสู่ระบบด้วย Facebook</div></button>
                    }
                    <div
                        onMouseEnter={() => {
                            setOpenUser(true);
                        }}
                        onMouseLeave={() => {
                            setOpenUser(false);
                        }}
                        className='flex z-40'
                    >
                        {
                            (!openUser) && (
                                <div className='font-bold text-white mr-[70px] mt-1 absolute right-0'>
                                    {
                                        user?.displayName
                                    }
                                </div>
                            )
                        }
                        {
                            openUser && user && (
                                <button
                                    onClick={() => {
                                        const myPromise = logOut();
                                        toast.promise(myPromise, {
                                            loading: 'Loading',
                                            success: 'SignOut Successfully',
                                            error: 'Error when signingout',
                                        })
                                            .then((r) => r)
                                            .catch((error) => error);
                                    }}
                                    className='font-bold text-black mr-4 mt-1 right-0 pl-10 pr-10 bg-slate-100 rounded-lg active:bg-slate-200'>
                                    SignOut
                                </button>
                            )
                        }
                        {
                            user && (
                                <img className="w-8 h-8 rounded-full mr-6" src={photo} alt={user?.displayName} referrerPolicy="no-referrer" />
                            )
                        }
                    </div>
                </div>
            </div>
            <a rel="noopener noreferrer" target="_blank" href="https://sites.google.com/pimandek.ac.th/pimandek" className='fixed flex w-full text-center bottom-0 left-[50%] z-50 translate-x-[-50%] text-[20px] font-bold pimandek text-white'>
                {<div className='flex-grow flex'>
                    <div className='bg-gradient-to-l w-full from-indigo-500 via-purple-500 to-pink-500 hover:bg-gradient-to-bl active:bg-gradient-to-r p-2'>
                        <div className='float-left'>
                            🏫
                        </div>
                        เข้าสู่หน้าหลักโรงเรียนพิมานเด็ก
                        <div className='float-right'>
                            🚌
                        </div>
                    </div>
                </div>}
            </a>
            {/* {
                serverIsOpen && (
                    <img onClick={() => {
                        navigate('/pims');
                    }} src={PIMSLogo} className='fixed right-[10px] z-[999] w-[100px] bottom-[50px] hover:w-[90px] hover:bottom-[55px] hover:right-[15px] active:w-[80px] active:bottom-[60px] active:right-[20px] animate-bounce' />
                )
            } */}
        </>
    )
}

export default Navigation